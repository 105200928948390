.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.color {
  color: #bed3d6;
}

@font-face {
  font-family: 'monoton';
  src: url('./font/Monoton-Regular.ttf');
}

@font-face {
  font-family: 'wehaven-bold';
  src: url('./font/Warhaven_Bold.ttf');
}

@font-face {
  font-family: 'wehaven-regular';
  src: url('./font/Warhaven_Regular.ttf');
}

@font-face {
  font-family: 'tenada';
  src: url('./font/Tenada.ttf');
}

@font-face {
  font-family: 'giants-bold';
  src: url('./font/Giants-Bold.ttf');
}

@font-face {
  font-family: 'giants-regular';
  src: url('./font/Giants-Regular.ttf');
}

@font-face {
  font-family: 'giants-inline';
  src: url('./font/Giants-Inline.ttf');
}

@font-face {
  font-family: 'ppeace-bold';
  src: url('./font/PyeongChangPeace-Bold.ttf');
}

@font-face {
  font-family: 'ppeace-light';
  src: url('./font/PyeongChangPeace-Light.ttf');
}
